const { host } = window.location;

function findEnv() {
  if (host.includes('localhost')) return 'local';
  if (host.includes('cleverapps')) return 'develop';
  return 'production';
}

export const ENV = findEnv();

console.log('ENV', ENV);

export const BFF_AUTH_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBLZXkiOiIxIiwiYXBwTmFtZSI6ImZyb250LW1vdG9yaXN0cyIsImFwcFZlcnNpb24iOiJ2MSIsImlhdCI6MTY1NDg2Mzg4NX0.kb7b4DPauPBQL4x2sllAQTa7iCJvkyDyLwj0YikFAwk';

const BFF_HOSTS = {
  local: 'http://localhost:3101',
  develop: process.env.REACT_APP_HOST_BFF_OSP_MOTORISTS || 'https://bff-motorists.cleverapps.io',
  production:
    process.env.REACT_APP_HOST_BFF_OSP_MOTORISTS || 'https://bff-motorists.openswissparking.ch',
};

export const BFF_HOST = BFF_HOSTS[ENV];
export const GRAPHQL_HOST = `${BFF_HOST}/graphql`;
